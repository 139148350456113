<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Tindakan Medis'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'medical-actions/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from "@/component/medical-actions/FormAddMedicines.vue";
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        patient_id: null,
        patient_name: "",
        patient_age: "",
        electro_laser: "",
        action_type: "",
        operator: "",
        responsible_doctor:"",
        anesteshia: "",
        anesthetist: null,
        notes: "",
        nurse_notes: "",
        date: "",
        medicines: "",
      },
      electro_laser: [],
      operator: [],
      action_type_id: [],
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('medical-actions/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/medical-actions/list')
      } else {
        this.form['_method'] = 'put'
        console.log("formedit", this.form)
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Tindakan Medis", route: "/medical-actions/list" },
      { title: "Ubah" },
    ])
    // Get Data
    this.get()
  },

}

</script>