<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Date -->
        <b-form-group id="input-group-date" label="Tanggal:" label-for="input-date">
          <v-dialog ref="dialog" v-model="modal2" :return-value.sync="form.date" persistent width="350px">
            <template v-slot:activator="{ on, attrs }">
              <b-form-input readonly v-bind="attrs" v-on="on" id="input-trigger-modal-date" v-model="form.date">
              </b-form-input>
            </template>

            <v-date-picker locale="id" v-if="modal2" v-model="form.date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-patient" label="Pasien:" label-for="input-trigger-modal-patient">
          <b-form-input id="input-trigger-modal-patient" v-model="display.patient_name" placeholder="Pilih Pasien"
            readonly @click="$bvModal.show('modal-patient')">
          </b-form-input>
          <small class="text-danger">{{ error.patient_id }}</small>
        </b-form-group>

        <!-- Input  Operator-->
        <b-form-group id="input-group-operator">
          <label for="input-operator">Operator: <em class="text-muted">opsional</em></label>
          <treeselect @select="setOperator" @deselect="removeOperator" v-model="operator" :multiple="true"
            :options="doctors" />
          <small class="text-danger">{{ error.operator }}</small>
        </b-form-group>

        <!-- Input  Operator-->
        <b-form-group id="input-group-operator">
          <label for="input-operator">Dokter Penanggung jawab:
            <em class="text-muted">opsional</em></label>
          <treeselect @select="setResponsibleDoctor" @deselect="removeResponsibleDoctor" v-model="responsibleDoctor"
            :multiple="true" placeholder="Pilih Dokter Penanggung Jawab" :options="doctors" />
          <small class="text-danger">{{ error.responsible_doctor }}</small>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-assistant">
          <label for="input-trigger-modal-assistant">Asisten Kamar Operasi: <em class="text-muted">opsional</em></label>
          <b-input-group>
            <b-form-input id="input-trigger-modal-assistant" v-model="display.assistant_name"
              placeholder="Pilih Asisten" readonly @click="$bvModal.show('modal-nurse')">
            </b-form-input>
            <template #append>
              <b-input-group-text squared :class="btnClearMedicineClass" style="border-left: none"
                @click="resetAssistant" @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'">
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.assistant }}</small>
        </b-form-group>

        <b-form-group id="input-group-trigger-modal-assistant">
          <label for="input-trigger-modal-assistant">Omploop: <em class="text-muted">opsional</em></label>
          <b-input-group>
            <b-form-input id="input-trigger-modal-assistant" v-model="display.omploop_name" placeholder="Pilih Omploop"
              readonly @click="$bvModal.show('modal-omploop')">
            </b-form-input>
            <template #append>
              <b-input-group-text squared :class="btnClearMedicineClass" style="border-left: none" @click="resetOmploop"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'" @mouseout="btnClearMedicineClass = 'bg-white'">
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.omploop }}</small>
        </b-form-group>

        <b-form-group id="input-group-trigger-modal-assistant">
          <label for="input-trigger-modal-assistant">Tindakan Perawat: <em class="text-muted">opsional</em></label>
          <b-input-group>
            <b-form-input id="input-trigger-modal-assistant" v-model="display.nurse_action_name"
              placeholder="Pilih Perawat" readonly @click="$bvModal.show('modal-nurse-action')">
            </b-form-input>
            <template #append>
              <b-input-group-text squared :class="btnClearMedicineClass" style="border-left: none"
                @click="resetNurseAction" @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'">
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.nurse_action }}</small>
        </b-form-group>

        <b-form-group id="input-group-trigger-modal-assistant-svf">
          <label for="input-trigger-modal-assistant-svf"
            >Asisten SVF: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-assistant-svf"
              v-model="display.assistant_svf_name"
              placeholder="Pilih Asisten SVF"
              readonly
              @click="$bvModal.show('modal-assistant-svf')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetAssistantSvf"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.assistant_svf }}</small>
        </b-form-group>

        <b-form-group id="input-group-trigger-modal-omploop-svf">
          <label for="input-trigger-modal-omploop-svf"
            >Omploop SVF: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-omploop-svf"
              v-model="display.omploop_svf_name"
              placeholder="Pilih Omploop SVF"
              readonly
              @click="$bvModal.show('modal-omploop-svf')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetOmploopSvf"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.omploop_svf }}</small>
        </b-form-group>

        <b-form-group id="input-group-trigger-modal-infus-svf">
          <label for="input-trigger-modal-infus-svf"
            >Infus SVF: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-infus-svf"
              v-model="display.infus_svf_name"
              placeholder="Pilih Infus SVF"
              readonly
              @click="$bvModal.show('modal-infus-svf')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetInfusSvf"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.infus_svf }}</small>
        </b-form-group>

        <!-- Description input -->
        <b-form-group id="input-group-nurse-notes">
          <label for="input-nurse-notes">Catatan Perawat: <em class="text-muted">opsional</em></label>
          <b-form-textarea id="input-nurse-notes" v-model="form.nurse_notes" placeholder="Catatan Perawat" rows="4"
            max-rows="8"></b-form-textarea>
          <small class="text-danger">{{ error.nurse_notes }}</small>
        </b-form-group>

        <!-- Input  Action Type-->
        <b-form-group id="input-group-action-type" label="Jenis Tindakan:" label-for="input-action-type">
          <treeselect v-model="action_type" @select="setActionType" @deselect="removeActionType" :multiple="true"
            :options="action_types" />
          <small class="text-danger">{{ error.action_type }}</small>
        </b-form-group>

        <!-- Input Anesthesia -->
        <!-- <b-form-group
          id="input-group-anesteshia"
          label="Anastesi:"
          label-for="input-anesteshia"
        >
          <b-form-input
            id="input-anesteshia"
            v-model="form.anesteshia"
            placeholder="Anestesi"
          ></b-form-input>
          <small class="text-danger">{{ error.anesteshia }}</small>
        </b-form-group> -->
        <b-form-group label="Anestesi:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group id="radio-group-1" v-model="form.anesteshia" :options="anesteshia"
            :aria-describedby="ariaDescribedby" name="radio-options"></b-form-radio-group>
        </b-form-group>

        <!-- Name input -->
        <b-form-group id="input-group-anesthetist" label="Dokter Anastesi:" label-for="input-anesthetist">
          <treeselect @select="setAnesthetist" @deselect="removeAnesthetist" v-model="anesthetist" :multiple="true"
            :options="doctors" />
          <small class="text-danger">{{ error.anesthetist }}</small>
        </b-form-group>

        <b-form-group
            id="input-group-anesthesia_nurse"
          >
          <label for="input-anesthesia_nurse">Penata Anestesi: <em class="text-muted">opsional</em></label>
            <b-form-input
              id="input-anesthesia_nurse"
              v-model="form.anesthesia_nurse"
              placeholder="Penata Anestesi"
            >
            </b-form-input>
            <small class="text-danger">{{ error.anesthesia_nurse }}</small>
          </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine" v-if="medicine_first_launch.length != 0">
          <label for="input-trigger-modal-medicine">Obat</label>
          <b-input-group>
            <b-form-input id="input-trigger-modal-medicine" v-model="display.medicine_name"
              placeholder="Pilih Obat dan Alkes" readonly @click="$bvModal.show('modal-medicine')"
              style="border-right: none">
            </b-form-input>
            <template #append>
              <b-input-group-text squared :class="btnClearMedicineClass" style="border-left: none"
                @click="resetMedicine" @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'">
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <!-- <small class="text-danger">* Daftar Obat Tidak Bisa Di Ubah</small> -->
        </b-form-group>

        <!-- <b-form-group
          id="input-group-trigger-modal-medicine"
          v-if="medicine_first_launch.length == 0"
        >
          <label for="input-trigger-modal-medicine">Obat dan Alkes Digunakan:</label>
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Obat dan Alkes"
              readonly
              style="border-right: none"
              @click="$bvModal.show('modal-medicine')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group> 
      
         <div class="bg-light" v-if="coll_medicine.length > 0">
          <table class="table" v-if="coll_medicine.length > 0">
            <tr>
              <th>Nama</th>
              <th>Kuantitas</th>
              <th>Harga Jual</th>
              <th>Total Harga</th>
        </tr>
        <tr v-for="mdc in coll_medicine" :key="mdc.id">
          <td>{{ mdc.name }}</td>
          <td>{{ mdc.quantity }}</td>
          <td>{{ mdc.sales_price }}</td>
          <td>{{ mdc.total_price }}</td>
        </tr>
        </table>
    </div>
    -->

        <b-form-group id="input-group-trigger-modal-medicine" v-if="medicine_first_order_launch.length == 0">
          <label for="input-trigger-modal-medicine">Obat/Alkes Dibawa Pulang</label>
          <b-input-group>
            <b-form-input id="input-trigger-modal-medicine" v-model="display.medicine_order"
              placeholder="Pilih Obat dan Alkes" readonly style="border-right: none"
              @click="$bvModal.show('modal-medicine')">
            </b-form-input>
            <template #append>
              <b-input-group-text squared :class="btnClearMedicineClass" style="border-left: none"
                @click="resetMedicine" @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'">
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>

        <div class="bg-light" v-if="coll_medicine.length > 0">
          <table class="table" v-if="coll_medicine.length > 0">
            <tr>
              <th>Nama</th>
              <th>Kuantitas</th>
              <th>Harga Jual</th>
              <th>Total Harga</th>
              <td>Aksi</td>
            </tr>
            <tr v-for="(mdc, i) in coll_medicine" :key="i">
              <td>{{ mdc.name }}</td>
              <td>{{ mdc.quantity }}</td>
              <td>{{ mdc.sales_price }}</td>
              <td>{{ mdc.total_price }}</td>
              <td><b-button size="sm" class="btn-danger" v-b-tooltip.hover title="Hapus" @click="unsetMedicine(i)">
                  <i class="fas fa-trash px-0"></i></b-button></td>
            </tr>
          </table>
        </div>

        <div class="bg-light" v-if="coll_medicine_order.length > 0">
          <table class="table">
            <tr>
              <th>Nama</th>
              <th>Kuantitas</th>
              <th>Harga Jual</th>
              <th>Total Harga</th>
              <!-- <td>Aksi</td> -->
            </tr>
            <tr v-for="mdco in coll_medicine_order" :key="mdco.id">
              <td>{{ mdco.name }}</td>
              <td>{{ mdco.quantity }}</td>
              <td>{{ mdco.sales_price }}</td>
              <td>{{ mdco.total_price }}</td>
              <!-- <td>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover.left
                  title="Hapus Obat dan Alkes"
                  type="button"
                  @click="removeMedicine(mdc.id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </td> -->
            </tr>
          </table>
        </div>

        <!-- Input Attachment -->
        <b-form-group id="input-group-file" label="Lampiran:" label-for="input-file">
          <b-form-file multiple placeholder="Upload Lampiran" drop-placeholder="Drop file here..."
            @change="upload"></b-form-file>
          <small class="text-danger">{{ error.attachment }}</small>
        </b-form-group>

        <div v-for="(attachment, index) in attachment" :key="attachment" class="col-md-6">
          <b-card class="m-2 shadow-sm" v-if="attachment.length >= 1 && attachment[0] != ''">
            <b-card-text class="d-flex justify-content-between">
              <span>{{ file_name[index] }}</span>
              <span style="cursor: pointer" @click="deleteAttach(index)">X</span>
            </b-card-text>
          </b-card>
        </div>

        <!-- Description input -->
        <b-form-group id="input-group-notes" label="Keterangan:" label-for="input-notes">
          <b-form-textarea id="input-notes" v-model="form.notes" placeholder="Keterangan" rows="4"
            max-rows="8"></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button type="button" class="ml-2" variant="default" @click="$router.push('/medical-actions')">
          Batal
        </b-button>
      </b-form>

      <!-- Input Medicine -->
      <b-modal ref="my-modal" hide-footer title="Resep Obat" @hide="hideModalCancel">
        <b-form @submit.stop.prevent="medicineFormOnsubmit">
          <!-- <div class="d-block text-center"> -->
          <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
            <b-form-input id="input-name" v-model="coll_medicine_form.name" placeholder="Nama obat"
              readonly></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-quantity" label="Kuantitas:" label-for="input-quantity">
            <b-form-input id="input-quantity" v-model="coll_medicine_form.quantity" placeholder="Kuantitas"
              required></b-form-input>
          </b-form-group>
          <!-- </div> -->
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button type="button" class="ml-2" variant="default" @click="hideModalCancel">
            Cancel
          </b-button>
        </b-form>
      </b-modal>

      <ModalPatient @chosenPatient="setPatient" @submitedPatient="setPatient" />
      <ModalNurse @chosenEmployee="setAssistant" :selectedItem="coll_assistant" :checkbox="true" />
      <ModalOmploop @chosenEmployee="setOmploop" :selectedItem="coll_omploop" :checkbox="true" />
      <ModalNurseAction @chosenEmployee="setNurseAction" :selectedItem="coll_nurse_action" :checkbox="true" />
      <ModalMedicine @chosenMedicine="setPrescription" @packageMedicine="setPackagePrescription"
        :selectedItem="coll_medicine" :checkbox="true" />
        <ModalAssistantSvf
        @chosenEmployee="setAssistantSvf"
        :selectedItem="coll_assistant_svf"
        :checkbox="true"
      />
      <ModalOmploopSvf
        @chosenEmployee="setOmploopSvf"
        :selectedItem="coll_omploop_svf"
        :checkbox="true"
      />
      <ModalInfusSvf
        @chosenEmployee="setInfusSvf"
        :selectedItem="coll_infus_svf"
        :checkbox="true"
      />
      <!-- <ModalMedicine2
        @chosenMedicine="setPrescription"
        @packageMedicine="setPackagePrescription"
        :selectedItem="coll_medicine"
        :checkbox="true"
      /> -->
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalPatient from "@/component/general/ModalPatient.vue";
import ModalNurse from "@/component/general/ModalNurse.vue";
import ModalNurseAction from "@/component/general/ModalNurseAction.vue";
import ModalOmploop from "@/component/general/ModalOmploop.vue";
import ModalMedicine from "@/component/general/ModalMedicineAction.vue";
import ModalMedicine2 from "@/component/general/ModalMedicine2.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";
import ModalAssistantSvf from "@/component/general/ModalAssistantSvf.vue";
import ModalOmploopSvf from "@/component/general/ModalOmploopSvf.vue";
import ModalInfusSvf from "@/component/general/ModalInfusSvf.vue";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalPatient,
    ModalNurse,
    ModalNurseAction,
    ModalOmploop,
    ModalMedicine,
    ModalMedicine2,
    ModalAssistantSvf,
    ModalOmploopSvf,
    ModalInfusSvf,
  },

  data() {
    return {
      modal2: false,
      // Display Data
      display: {
        patient_name: "",
        assistant_name: "",
        omploop_name: "",
        nurse_action_name: "",
        medicine_name: "",
        medicine_order: "",
      },
      file_name: [],
      // medicine first launch
      medicine_first_launch: "",
      medicine_first_order_launch: "",
      // Error
      error: {
        patient_id: null,
        patient_name: "",
        patient_age: "",
        electro_laser: "",
        action_type: "",
        operator: "",
        responsible_doctor: "",
        omploop: "",
        nurse_action: "",
        anesteshia: "",
        anesthetist: null,
        notes: "",
        date: "",
      },
      doctors: [],
      action_types: [],
      anesteshia: [
        { text: "Lokal", value: "Lokal" },
        { text: "Sedasi", value: "Sedasi" },
        { text: "Spinal", value: "Spinal" },
        { text: "Umum", value: "Umum" },
      ],
      operator: [],
      responsibleDoctor: [],
      anesthetist: [],
      action_type: [],
      //   set input
      // Operator
      coll_operator_actv: [],
      coll_operator_form: {
        id: "",
        name: "",
      },
      coll_operator: [],
      coll_operator_str: "",
      // responsible doctor
      coll_responsibleDoctor_form: {
        id: "",
        name: "",
      },
      coll_responsibleDoctor_actv: [],
      coll_responsibleDoctor: [],
      coll_responsibleDoctor_str: "",
      // Anesthetist
      coll_anesthetist_actv: [],
      coll_anesthetist_form: {
        id: "",
        name: "",
      },
      coll_anesthetist: [],
      coll_anesthetist_str: "",
      // Action Type
      coll_action_type_actv: [],
      coll_action_type_form: {
        id: "",
        name: "",
      },
      coll_action_type: [],
      coll_action_type_str: "",
      // Assistant
      coll_assistant_actv: [],
      coll_assistant_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_assistant: [],
      coll_assistant_str: "",
      // omploop
      coll_omploop_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_omploop: [],
      coll_omploop_str: "",

      // nurse action
      coll_nurse_action_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_nurse_action: [],
      coll_nurse_action_str: "",

      coll_assistant_svf_actv: [],
      coll_assistant_svf: [],
      coll_assistant_svf_str: "",
      coll_assistant_svf_form: {
        id: "",
        name: "",
        position: "",
      },

      coll_omploop_svf_actv: [],
      coll_omploop_svf: [],
      coll_omploop_svf_str: "",
      coll_omploop_svf_form: {
        id: "",
        name: "",
        position: "",
      },

      coll_infus_svf_actv: [],
      coll_infus_svf: [],
      coll_infus_svf_str: "",
      coll_infus_svf_form: {
        id: "",
        name: "",
        position: "",
      },

      // Medicine
      coll_medicine_actv: [],
      coll_medicine_form: {
        id: "",
        name: "",
        sales_price: "",
        total_price: "",
      },
      coll_medicine: [],
      coll_medicine_str: "",

      // Medicine
      coll_medicine_order_actv: [],
      coll_medicine_order_form: {
        id: "",
        name: "",
        sales_price: "",
        total_price: "",
      },
      coll_medicine_order: [],
      coll_medicine_order_str: "",

      //
      uploadAttachmentURL: "",
      // data
      formData: new FormData(),
      attachment: "",
      // other
      btnClearMedicineClass: "bg-white",
      medicine_collection_submit: false,
      medicine_id: "",
      medicine: "",
      name_medicine: "",
      // Pagination
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "action_type",
          label: "Diagnosa Tindakan",
          sortable: true,
        },
        {
          key: "operator",
          label: "Operator",
          sortable: true,
        },
        {
          key: "assistant",
          label: "Asisten",
          sortable: true,
        },
        {
          key: "anesteshia",
          label: "Anastesi",
          sortable: true,
        },
        {
          key: "anesthetist",
          label: "Dokter Anastesi",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat/Alkes",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
    };
  },

  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    async pagination(id) {
      let filterParams = ``;
      let response = await module.paginate(
        "medical-actions/get-by-patient/" + id,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        let date = new Date(response.data[a].date);
        let convert = date.toLocaleString().split(",")[0];
        response.data[a].number = b + a;
        response.data[a].date = convert;
      }

      this.items = response.data;

      this.$bvModal.hide("modal-patient");
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.pagination(value.id);
    },

    setAssistant(value) {
      if (this.coll_assistant.length > 0) {
        let a;
        for (a = 0; a < this.coll_assistant.length > 0; a++) {
          if (this.coll_assistant[a].id == value.id) {
            this.coll_assistant.splice(a, 1);

            if (this.coll_assistant.length == 0) {
              this.display.assistant_name = "";
              break;
            } else if (this.coll_assistant.length > 0) {
              this.displayAssistanName(this.coll_assistant);
              break;
            }
            break;
          } else if (a == this.coll_assistant.length - 1) {
            this.display.assistant_name = value.name;
            this.coll_assistant_form.id = value.id;
            this.coll_assistant_form.name = value.name;
            this.coll_assistant_form.position = value.position;

            let clone = { ...this.coll_assistant_form };
            this.coll_assistant.push(clone);

            this.displayAssistanName(this.coll_assistant);
            break;
          }
        }
      } else {
        this.display.assistant_name = value.name;
        this.coll_assistant_form.id = value.id;
        this.coll_assistant_form.name = value.name;
        this.coll_assistant_form.position = value.position;

        let clone = { ...this.coll_assistant_form };
        this.coll_assistant.push(clone);

        this.displayAssistanName(this.coll_assistant);
      }
    },

    setOmploop(value) {
      if (this.coll_omploop.length > 0) {
        let a;
        for (a = 0; a < this.coll_omploop.length > 0; a++) {
          if (this.coll_omploop[a].id == value.id) {
            this.coll_omploop.splice(a, 1);

            if (this.coll_omploop.length == 0) {
              this.display.omploop_name = "";
              break;
            } else if (this.coll_omploop.length > 0) {
              this.displayOmploopName(this.coll_omploop);
              break;
            }
            break;
          } else if (a == this.coll_omploop.length - 1) {
            this.display.omploop_name = value.name;
            this.coll_omploop_form.id = value.id;
            this.coll_omploop_form.name = value.name;
            this.coll_omploop_form.position = value.position;

            let clone = { ...this.coll_omploop_form };
            this.coll_omploop.push(clone);

            this.displayOmploopName(this.coll_omploop);
            break;
          }
        }
      } else {
        this.display.omploop_name = value.name;
        this.coll_omploop_form.id = value.id;
        this.coll_omploop_form.name = value.name;
        this.coll_omploop_form.position = value.position;

        let clone = { ...this.coll_omploop_form };
        this.coll_omploop.push(clone);

        this.displayOmploopName(this.coll_omploop);
      }
    },

    setNurseAction(value) {
      if (this.coll_nurse_action.length > 0) {
        let a;
        for (a = 0; a < this.coll_nurse_action.length > 0; a++) {
          if (this.coll_nurse_action[a].id == value.id) {
            this.coll_nurse_action.splice(a, 1);

            if (this.coll_nurse_action.length == 0) {
              this.display.nurse_action_name = "";
              break;
            } else if (this.coll_nurse_action.length > 0) {
              this.displayNurseActionName(this.coll_nurse_action);
              break;
            }
            break;
          } else if (a == this.coll_nurse_action.length - 1) {
            this.display.nurse_action_name = value.name;
            this.coll_nurse_action_form.id = value.id;
            this.coll_nurse_action_form.name = value.name;
            this.coll_nurse_action_form.position = value.position;

            let clone = { ...this.coll_nurse_action_form };
            this.coll_nurse_action.push(clone);

            this.displayNurseActionName(this.coll_nurse_action);
            break;
          }
        }
      } else {
        this.display.nurse_action_name = value.name;
        this.coll_nurse_action_form.id = value.id;
        this.coll_nurse_action_form.name = value.name;
        this.coll_nurse_action_form.position = value.position;

        let clone = { ...this.coll_nurse_action_form };
        this.coll_nurse_action.push(clone);

        this.displayNurseActionName(this.coll_nurse_action);
      }
    },

    setAssistantSvf(value) {
      if (this.coll_assistant_svf.length > 0) {
        let a;
        for (a = 0; a < this.coll_assistant_svf.length > 0; a++) {
          if (this.coll_assistant_svf[a].id == value.id) {
            this.coll_assistant_svf.splice(a, 1);
            console.log("splice");
            if (this.coll_assistant_svf.length == 0) {
              this.display.assistant_svf_name = "";
              break;
            } else if (this.coll_assistant_svf.length > 0) {
              this.displayAssistanSvfName(this.coll_assistant_svf);
              break;
            }
            break;
          } else if (a == this.coll_assistant_svf.length - 1) {
            this.display.assistant_svf_name = value.name;
            this.coll_assistant_svf_form.id = value.id;
            this.coll_assistant_svf_form.name = value.name;
            this.coll_assistant_svf_form.position = value.position;

            let clone = { ...this.coll_assistant_svf_form };
            this.coll_assistant_svf.push(clone);
            console.log("coll_assistant_svf", this.coll_assistant_svf);
            this.displayAssistanSvfName(this.coll_assistant_svf);
            break;
          }
        }
      } else {
        this.display.assistant_svf_name = value.name;
        this.coll_assistant_svf_form.id = value.id;
        this.coll_assistant_svf_form.name = value.name;
        this.coll_assistant_svf_form.position = value.position;

        let clone = { ...this.coll_assistant_svf_form };
        this.coll_assistant_svf.push(clone);
        console.log("coll_assistant_svf", this.coll_assistant_svf);
        this.displayAssistanSvfName(this.coll_assistant_svf);
      }
    },

    setOmploopSvf(value) {
      console.log('set omploopppp')
      if (this.coll_omploop_svf.length > 0) {
        let a;
        for (a = 0; a < this.coll_omploop_svf.length > 0; a++) {
          if (this.coll_omploop_svf[a].id == value.id) {
            this.coll_omploop_svf.splice(a, 1);
            console.log("splice");
            if (this.coll_omploop_svf.length == 0) {
              this.display.omploop_svf_name = "";
              break;
            } else if (this.coll_omploop_svf.length > 0) {
              this.displayOmploopSvfName(this.coll_omploop_svf);
              break;
            }
            break;
          } else if (a == this.coll_omploop_svf.length - 1) {
            this.display.omploop_svf_name = value.name;
            this.coll_omploop_svf_form.id = value.id;
            this.coll_omploop_svf_form.name = value.name;
            this.coll_omploop_svf_form.position = value.position;

            let clone = { ...this.coll_omploop_svf_form };
            this.coll_omploop_svf.push(clone);
            console.log("coll_omploop_svf", this.coll_omploop_svf);
            this.displayOmploopSvfName(this.coll_omploop_svf);
            break;
          }
        }
      } else {
        this.display.omploop_svf_name = value.name;
        this.coll_omploop_svf_form.id = value.id;
        this.coll_omploop_svf_form.name = value.name;
        this.coll_omploop_svf_form.position = value.position;

        let clone = { ...this.coll_omploop_svf_form };
        this.coll_omploop_svf.push(clone);
        console.log("coll_omploop_svf", this.coll_omploop_svf);
        this.displayOmploopSvfName(this.coll_omploop_svf);
      }
    },

    setInfusSvf(value) {
      console.log('set infus')
      if (this.coll_infus_svf.length > 0) {
        let a;
        for (a = 0; a < this.coll_infus_svf.length > 0; a++) {
          if (this.coll_infus_svf[a].id == value.id) {
            this.coll_infus_svf.splice(a, 1);
            console.log("splice");
            if (this.coll_infus_svf.length == 0) {
              this.display.infus_svf_name = "";
              break;
            } else if (this.coll_infus_svf.length > 0) {
              this.displayInfusSvfName(this.coll_infus_svf);
              break;
            }
            break;
          } else if (a == this.coll_infus_svf.length - 1) {
            this.display.infus_svf_name = value.name;
            this.coll_infus_svf_form.id = value.id;
            this.coll_infus_svf_form.name = value.name;
            this.coll_infus_svf_form.position = value.position;

            let clone = { ...this.coll_infus_svf_form };
            this.coll_infus_svf.push(clone);
            console.log("coll_infus_svf", this.coll_infus_svf);
            this.displayInfusSvfName(this.coll_infus_svf);
            break;
          }
        }
      } else {
        this.display.infus_svf_name = value.name;
        this.coll_infus_svf_form.id = value.id;
        this.coll_infus_svf_form.name = value.name;
        this.coll_infus_svf_form.position = value.position;

        let clone = { ...this.coll_infus_svf_form };
        this.coll_infus_svf.push(clone);
        console.log("coll_infus_svf", this.coll_infus_svf);
        this.displayInfusSvfName(this.coll_infus_svf);
      }
    },

    resetAssistant() {
      this.coll_assistant.splice(0, this.coll_assistant.length);

      this.display.assistant_name = "";
    },

    resetOmploop() {
      this.coll_omploop.splice(0, this.coll_omploop.length);

      this.display.omploop_name = "";
    },
    resetNurseAction() {
      this.coll_nurse_action.splice(0, this.coll_nurse_action.length);

      this.display.nurse_action_name = "";
    },
    resetAssistantSvf() {
      this.coll_assistan_svf.splice(0, this.coll_assistan_svf.length);
      console.log("reset", this.coll_assistan_svf);
      this.display.assistant_svf_name = "";
    },
    resetOmploopSvf() {
      this.coll_omploop_svf.splice(0, this.coll_omploop_svf.length);
      console.log("reset", this.coll_omploop_svf);
      this.display.omploop_svf_name = "";
    },
    resetInfusSvf() {
      this.coll_infus_svf.splice(0, this.coll_infus_svf.length);
      console.log("reset", this.coll_infus_svf);
      this.display.infus_svf_name = "";
    },
    // setMedicine(value) {
    //   let a;
    //   if (this.coll_medicine.length > 0) {
    //     for (a = 0; a < this.coll_medicine.length; a++) {

    //       if (this.coll_medicine[a].id == value.id) {

    //         Swal.fire("Gagal", "Obat dan Alkes tersebut telah dipilih", "warning");
    //         break;
    //       } else if (a == this.coll_medicine.length - 1) {
    //         // this.$bvModal.hide("modal-medicine");

    //         this.display.medicine_name = value.name;
    //         this.coll_medicine.id = value.id;
    //         this.coll_medicine.name = value.name;
    //         this.coll_medicine.sales_price = value.sales_price;

    //         let clone = { ...this.coll_medicine };
    //         this.coll_medicine.push(clone);

    //         this.displayMedicineName(this.coll_medicine);
    //         break;
    //       }
    //     }
    //   } else {
    //     this.display.medicine_name = value.name;
    //     this.coll_medicine.id = value.id;
    //     this.coll_medicine.name = value.name;
    //     this.coll_medicine.sales_price = value.sales_price;

    //     let clone = { ...this.coll_medicine };
    //     this.coll_medicine.push(clone);

    //     this.displayMedicineName(this.coll_medicine);

    //     // this.$bvModal.hide("modal-medicine");
    //   }
    // },

    async setPrescription(value) {
      if (this.coll_medicine.length > 0) {
        let a;
        for (a = 0; a < this.coll_medicine.length; a++) {
          if (this.coll_medicine[a].id == value.id) {
            this.coll_medicine.splice(a, 1);
            if (this.coll_medicine.length == 0) {
              this.display.medicine_name = "";
              break;
            } else if (this.coll_medicine.length > 0) {
              this.displayMedicineName(this.coll_medicine);
              break;
            }
            break;
          } else if (a == this.coll_medicine.length - 1) {
            this.$bvModal.hide("modal-medicine");

            await this.medicine_id;
            this.medicine_id = value.id;

            // this.coll_medicine_form.id = "";
            this.coll_medicine_form.name = "";
            this.coll_medicine_form.sales_price = "";
            this.coll_medicine_form.quantity = "";
            this.setMedicine(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine");

        await this.medicine_id;
        this.medicine_id = value.id;

        // this.coll_medicine_form.id = "";
        this.coll_medicine_form.name = "";
        this.coll_medicine_form.sales_price = "";
        this.coll_medicine_form.quantity = "";
        this.setMedicine(value.id);
      }
    },

    async setPrescriptionOrder(value) {
      if (this.coll_medicine_order.length > 0) {
        let a;
        for (a = 0; a < this.coll_medicine_order.length; a++) {
          if (this.coll_medicine_order[a].id == value.id) {
            this.coll_medicine_order.splice(a, 1);
            if (this.coll_medicine_order.length == 0) {
              this.display.medicine_order = "";
              break;
            } else if (this.coll_medicine_order.length > 0) {
              this.displayMedicineNameTakeaway(this.coll_medicine_order);
              break;
            }
            break;
          } else if (a == this.coll_medicine_order.length - 1) {
            this.$bvModal.hide("modal-medicine-order");

            await this.medicine_order_id;
            this.medicine_order_id = value.id;

            // this.coll_medicine_order_form.id = "";
            this.coll_medicine_order_form.name = "";
            this.coll_medicine_order_form.sales_price = "";
            this.coll_medicine_order_form.quantity = "";
            this.setMedicineTakeaway(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine-order");

        await this.medicine_order_id;
        this.medicine_order_id = value.id;

        // this.coll_medicine_form.id = "";
        this.coll_medicine_order_form.name = "";
        this.coll_medicine_order_form.sales_price = "";
        this.coll_medicine_order_form.quantity = "";
        this.setMedicineTakeaway(value.id);
      }
    },

    async setPackagePrescription(datas) {
      this.isPackagePrescription = true;
      this.datas = datas;
      this.displayMedicineName(datas);

      this.coll_medicine = datas;
    },

    async setPackagePrescriptionOrder(datas) {
      this.isPackagePrescription = true;
      this.datas = datas;
      this.displayMedicineNameTakeaway(datas);

      this.coll_medicine_order = datas;
    },

    setMedicine(id) {
      ApiService.get("medicines/" + id).then((response) => {
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.coll_medicine_form.name = response.data.data.name;
        this.coll_medicine_form.id = response.data.data.id;
        this.coll_medicine_form.sales_price = response.data.data.sales_price;
        this.$refs["my-modal"].show();
      });
    },

    setMedicineTakeaway(id) {
      ApiService.get("medicines/" + id).then((response) => {
        this.medicine_order = response.data.data;
        this.name_order_medicine = response.data.data.name;
        this.coll_medicine_order_form.name = response.data.data.name;
        this.coll_medicine_order_form.id = response.data.data.id;
        this.coll_medicine_order_form.sales_price =
          response.data.data.sales_price;
        this.$refs["my-modal-order"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    displayMedicineNameTakeaway(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_order_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_order_name = str;
      }
    },

    resetMedicine() {
      this.coll_medicine.splice(0, this.coll_medicine.length);

      this.display.medicine_name = "";
    },

    resetMedicineTakeaway() {
      this.coll_medicine_order.splice(0, this.coll_medicine_order.length);

      this.display.medicine_order = "";
    },

    removeMedicine($id) {
      let a;
      for (a = 0; a < this.coll_medicine.length; a++) {
        if (this.coll_medicine[a].id == $id) {
          this.coll_medicine.splice(a, 1);
        }
      }
      this.displayMedicineName(this.coll_medicine);
    },

    removeMedicineTakeaway($id) {
      let a;
      for (a = 0; a < this.coll_medicine_order.length; a++) {
        if (this.coll_medicine_order[a].id == $id) {
          this.coll_medicine_order.splice(a, 1);
        }
      }
      this.displayMedicineNameTakeaway(this.coll_medicine_order);
    },

    medicineFormOnsubmit() {

      this.coll_medicine_form.total_price =
        parseInt(this.coll_medicine_form.quantity) *
        parseInt(this.coll_medicine_form.sales_price);
      this.coll_medicine_form.total_price = parseInt(
        this.coll_medicine_form.total_price
      ).toLocaleString("id-ID");
      this.coll_medicine_form.sales_price = parseInt(
        this.coll_medicine_form.sales_price
      ).toLocaleString("id-ID");

      this.medicine_collection_submit = true;
      let clone = { ...this.coll_medicine_form };
      this.coll_medicine.push(clone);

      this.displayMedicineName(this.coll_medicine);
      this.hideModal();
    },

    onFileUpload(evt) {
      this.uploadAttachmentURL = URL.createObjectURL(evt.target.files[0]);

      this.form.attachment = evt.target.files[0];
    },

    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }
    },

    deleteAttach(index) {
      this.attachment.splice(index, 1);
      this.file_name.splice(index, 1);
    },

    getOperatorActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_operator_actv = JSON.parse(response.data.data.operator);
          this.coll_operator = JSON.parse(response.data.data.operator);
          this.display.patient_name = response.data.data.patient_name;

          this.pagination(response.data.data.patient_id);

          this.attachment = response.data.data.attachment_list.split(",");

          let b;
          for (b = 0; b < this.attachment.length; b++) {
            this.file_name.push(this.attachment[b].split("____")[1]);
          }

          let a;
          for (a = 0; this.coll_operator_actv.length; a++) {
            this.operator.push(this.coll_operator_actv[a].id);
          }
        }
      );
    },

    getAssistantActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_assistant_actv = JSON.parse(response.data.data.assistant);
          this.coll_assistant = JSON.parse(response.data.data.assistant);

          this.displayAssistanName(JSON.parse(response.data.data.assistant));

          // let a;
          // for (a = 0; this.coll_assistant_actv.length; a++) {
          //   this.assistant.push(this.coll_assistant_actv[a].id);

          // }
        }
      );
    },

    getOmploopActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_omploop_actv = JSON.parse(response.data.data.omploop);
          this.coll_omploop = JSON.parse(response.data.data.omploop);

          this.displayOmploopName(JSON.parse(response.data.data.omploop));

          // let a;
          // for (a = 0; this.coll_assistant_actv.length; a++) {
          //   this.assistant.push(this.coll_assistant_actv[a].id);

          // }
        }
      );
    },

    getNurseActionActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_nurse_action_actv = JSON.parse(
            response.data.data.nurse_action
          );
          this.coll_nurse_action = JSON.parse(response.data.data.nurse_action);

          this.displayNurseActionName(
            JSON.parse(response.data.data.nurse_action)
          );

          // let a;
          // for (a = 0; this.coll_assistant_actv.length; a++) {
          //   this.assistant.push(this.coll_assistant_actv[a].id);

          // }
        }
      );
    },

    getAssistantSvfActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_assistant_svf_actv = JSON.parse(response.data.data.assistant_svf);
          this.coll_assistant_svf = JSON.parse(response.data.data.assistant_svf);

          this.displayAssistanSvfName(JSON.parse(response.data.data.assistant_svf));
        }
      );
    },
    getOmploopSvfActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_omploop_svf_actv = JSON.parse(response.data.data.omploop_svf);
          this.coll_omploop_svf = JSON.parse(response.data.data.omploop_svf);

          this.displayOmploopSvfName(JSON.parse(response.data.data.omploop_svf));
        }
      );
    },
    getInfusSvfActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_infus_svf_actv = JSON.parse(response.data.data.infus_svf);
          this.coll_infus_svf = JSON.parse(response.data.data.infus_svf);

          this.displayInfusSvfName(JSON.parse(response.data.data.infus_svf));
        }
      );
    },

    displayAssistanName(assistant) {
      if (assistant.length == 0) {
        this.display.assistant_name = "";
      } else {
        let str = "";
        assistant.forEach(function (value, index) {
          str += value.name + `(${value.position})`;
          if (index != assistant.length - 1) {
            if (assistant.length > 1) str += ", ";
          }
        });
        this.display.assistant_name = str;
      }
    },
    displayOmploopName(omploop) {
      if (omploop.length == 0) {
        this.display.omploop_name = "";
      } else {
        let str = "";
        omploop.forEach(function (value, index) {
          str += value.name + `(${value.position})`;
          if (index != omploop.length - 1) {
            if (omploop.length > 1) str += ", ";
          }
        });
        this.display.omploop_name = str;
      }
    },

    displayNurseActionName(nurse_action) {
      if (nurse_action.length == 0) {
        this.display.nurse_action_name = "";
      } else {
        let str = "";
        nurse_action.forEach(function (value, index) {
          str += value.name + `(${value.position})`;
          if (index != nurse_action.length - 1) {
            if (nurse_action.length > 1) str += ", ";
          }
        });
        this.display.nurse_action_name = str;
      }
    },
    displayAssistanSvfName(assistant) {
      if (assistant.length == 0) {
        this.display.assistant_svf_name = "";
      } else {
        let str = "";
        assistant.forEach(function (value, index) {
          console.log("display assistant");
          str += value.name + `(${value.position})`;
          if (index != assistant.length - 1) {
            if (assistant.length > 1) str += ", ";
          }
        });
        this.display.assistant_svf_name = str;
      }
    },

     displayOmploopSvfName(assistant) {
      if (assistant.length == 0) {
        this.display.omploop_svf_name = "";
      } else {
        let str = "";
        assistant.forEach(function (value, index) {
          console.log("display assistant");
          str += value.name + `(${value.position})`;
          if (index != assistant.length - 1) {
            if (assistant.length > 1) str += ", ";
          }
        });
        this.display.omploop_svf_name = str;
      }
    },

    displayInfusSvfName(assistant) {
      if (assistant.length == 0) {
        this.display.infus_svf_name = "";
      } else {
        let str = "";
        assistant.forEach(function (value, index) {
          console.log("display assistant");
          str += value.name + `(${value.position})`;
          if (index != assistant.length - 1) {
            if (assistant.length > 1) str += ", ";
          }
        });
        this.display.infus_svf_name = str;
      }
    },

    getMedicineActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_medicine_actv = JSON.parse(response.data.data.medicines);
          this.coll_medicine = JSON.parse(response.data.data.medicines);
          this.medicine_first_launch = JSON.parse(response.data.data.medicines);

          this.displayMedicineName(JSON.parse(response.data.data.medicines));

          // let a;
          // for (a = 0; this.coll_medicine_actv.length; a++) {
          //   // this.medicines.push(this.coll_medicine_actv[a].id);

          // }
        }
      );
    },

    getMedicineActiveTakeaway() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_medicine_order_actv = JSON.parse(
            response.data.data.medicine_order
          );
          this.coll_medicine_order = JSON.parse(
            response.data.data.medicine_order
          );
          this.medicine_first_order_launch = JSON.parse(
            response.data.data.medicine_order
          );
          console.log(response.data.data.medicine_order, "lolllll");

          this.displayMedicineNameTakeaway(
            JSON.parse(response.data.data.medicine_order)
          );
        }
      );
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    getActionTypeActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_action_type_actv = JSON.parse(
            response.data.data.action_type
          );
          this.coll_action_type = JSON.parse(response.data.data.action_type);

          let a;
          for (a = 0; this.coll_action_type_actv.length; a++) {
            this.action_type.push(this.coll_action_type_actv[a].id);
          }
        }
      );
    },

    getAnthetistActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_anesthetist_actv = JSON.parse(
            response.data.data.anesthetist
          );
          this.coll_anesthetist = JSON.parse(response.data.data.anesthetist);

          let a;
          for (a = 0; this.coll_anesthetist_actv.length; a++) {
            this.anesthetist.push(this.coll_anesthetist_actv[a].id);
          }
        }
      );
    },
    getResponsibleDoctorActive() {
      ApiService.get("medical-actions/" + this.$route.params.id).then(
        (response) => {
          this.coll_responsibleDoctor_actv = JSON.parse(
            response.data.data.responsible_doctor
          );
          this.coll_responsibleDoctor = JSON.parse(
            response.data.data.responsible_doctor
          );

          let a;
          for (a = 0; this.coll_responsibleDoctor_actv.length; a++) {
            this.responsibleDoctor.push(this.coll_responsibleDoctor_actv[a].id);
          }
        }
      );
    },

    async getDoctorOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getActionTypes() {
      let response = await module.setTreeSelect("payment-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.action_types = response.data;
        this.action_types.unshift({
          label: "Pilih Jenis Tindakan",
          id: "",
          isDisabled: true,
        });
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      this.patient_age = age;
      // return age;
    },

    setOperator(evt) {
      this.coll_operator_form.id = evt.id;
      this.coll_operator_form.name = evt.label;

      let clone = { ...this.coll_operator_form };
      this.coll_operator.push(clone);
    },

    removeOperator(event) {
      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }

      let a;
      for (a = 0; a < this.coll_operator.length; a++) {
        if (event.id == this.coll_operator[a].id) {
          this.coll_operator.splice(a, 1);
        }
      }
    },

    setResponsibleDoctor(evt) {
      this.coll_responsibleDoctor_form.id = evt.id;
      this.coll_responsibleDoctor_form.name = evt.label;

      let clone = { ...this.coll_responsibleDoctor_form };
      this.coll_responsibleDoctor.push(clone);
    },

    removeResponsibleDoctor(event) {
      let a;
      for (a = 0; a < this.coll_responsibleDoctor.length; a++) {
        if (event.id == this.coll_responsibleDoctor[a].id) {
          this.coll_responsibleDoctor.splice(a, 1);
        }
      }
    },

    setAnesthetist(evt) {
      this.coll_anesthetist_form.id = evt.id;
      this.coll_anesthetist_form.name = evt.label;

      let clone = { ...this.coll_anesthetist_form };
      this.coll_anesthetist.push(clone);
    },

    removeAnesthetist(event) {
      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }

      let a;
      for (a = 0; a < this.coll_anesthetist.length; a++) {
        if (event.id == this.coll_anesthetist[a].id) {
          this.coll_anesthetist.splice(a, 1);
        }
      }
    },

    setActionType(evt) {
      this.coll_action_type_form.id = evt.id;
      this.coll_action_type_form.name = evt.label;

      let clone = { ...this.coll_action_type_form };
      this.coll_action_type.push(clone);
    },

    removeActionType(event) {
      let a;
      for (a = 0; a < this.coll_action_type.length; a++) {
        if (event.id == this.coll_action_type[a].id) {
          this.coll_action_type.splice(a, 1);
        }
      }
    },

    unsetMedicine(i) {
      this.coll_medicine.splice(i, 1);
    },

    async formOnsubmit() {
      let attachments = this.attachment.join();

      this.formData.append("attachment_list", attachments);

      this.coll_responsibleDoctor_str = await JSON.stringify(
        this.coll_responsibleDoctor
      );
      this.form.responsible_doctor = await this.coll_responsibleDoctor_str;

      this.coll_operator_str = await JSON.stringify(this.coll_operator);
      this.form.operator = await this.coll_operator_str;

      this.coll_anesthetist_str = await JSON.stringify(this.coll_anesthetist);
      this.form.anesthetist = await this.coll_anesthetist_str;

      this.coll_action_type_str = await JSON.stringify(this.coll_action_type);
      this.form.action_type = await this.coll_action_type_str;

      this.coll_omploop_str = await JSON.stringify(this.coll_omploop);
      this.form.omploop = await this.coll_omploop_str;

      this.coll_nurse_action_str = await JSON.stringify(this.coll_nurse_action);
      this.form.nurse_action = await this.coll_nurse_action_str;

      this.coll_assistant_str = await JSON.stringify(this.coll_assistant);
      this.form.assistant = await this.coll_assistant_str;

      this.coll_assistant_svf_str = await JSON.stringify(this.coll_assistant_svf);
      this.form.assistant_svf = await this.coll_assistant_svf_str;

      this.coll_omploop_svf_str = await JSON.stringify(this.coll_omploop_svf);
      this.form.omploop_svf = await this.coll_omploop_svf_str;

      this.coll_infus_svf_str = await JSON.stringify(this.coll_infus_svf);
      this.form.infus_svf = await this.coll_infus_svf_str;

      this.coll_medicine_str = await JSON.stringify(this.coll_medicine);
      this.form.medicines = await this.coll_medicine_str;

      // let formData = new FormData();
      // for (const [key, value] of Object.entries(this.form)) {
      //   formData.append(key, value);
      // }
      // // Make Request
      // let response = await module.submit(formData, this.route);
      for (const [key, value] of Object.entries(this.form)) {
        if (key !== "attachment_list") {
          this.formData.append(key, value);
        }
        // else {
        //   formData.append(key, value);
        // }
        // this.formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medical-actions");
      }
    },
  },

  mounted() {
    this.getDoctorOption();
    this.getActionTypes();

    this.getOperatorActive();
    this.getActionTypeActive();
    this.getAnthetistActive();
    this.getResponsibleDoctorActive();
    this.getAssistantActive();
    this.getMedicineActive();
    this.getMedicineActiveTakeaway();
    this.getOmploopActive();
    this.getNurseActionActive();
    this.getAssistantSvfActive();
    this.getOmploopSvfActive();
    this.getInfusSvfActive();
  },

  watch: {
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
  },
};
</script>

<style></style>