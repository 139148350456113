<template>
  <v-app>
    <div>

      <!-- Filter -->
      <div class="row justify-content-start mt-n3">
        <div class="col-md-3" v-if="purpose == 'modal' && checkbox && isSortcut">
          <b-input-group>
            <treeselect id="package" placeholder="Shortcut Obat" v-model="filter.medicine_package_id" :multiple="true"
              :options="medicine_packages" @change="packageMedicine" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <b-input-group>
            <b-form-input type="text" v-model="filter.name" placeholder="Saring Nama"></b-form-input>
            <template #append>
              <b-button squared @click="filterByName" variant="success">Cari</b-button>
              <b-button squared @click="resetFilter" variant="danger">Reset</b-button>
            </template>
          </b-input-group>
        </div>
        <div class="col-md-3">
          <b-input-group>
            <treeselect v-model="filter.medicine_category_id" :multiple="false" :options="medicine_categories"
              @input="filterByMedicineCategory" />
          </b-input-group>
        </div>
        <div class="col-md-3" v-if="purpose != 'modal'">
          <b-form-select id="input-ecommerce" v-model="filter.EcommerceFilter" :options="sortEcommerce"
            @change="pagination">
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select id="input-period" v-model="perPage" :options="formats" @change="pagination"
            placeholder="Per Halaman (Def. 20)"></b-form-select>
        </div>
      </div>

      <div class="d-flex justify-content-start align-items-center mt-5" v-if="purpose != 'modal'">
        <button class="btn btn-primary mr-1" @click="btnExportOnClick">
          Expor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>

      <b-table striped hover :items="items" :fields="fieldPopup" responsive v-if="purpose == 'modal'">
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{
          width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '',
          width: field.key == 'generic_name' ? '15%' : '',
        }" />
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" class="mr-1 btn-info" @click="chooseMedicine(data.item)">
            Pilih
          </b-button>
        </template>
      </b-table>

      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @page-click="pageOnClick"
        class="mt-4">
      </b-pagination>
    </div>
  </v-app>
</template>

<script>
import XLSX from "xlsx";
import module from "@/core/modules/CrudModule.js";
import ModalMedicineReport from "@/component/general/ModalMedicineReport.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    purpose: String,
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    modalPurpose: String,
    secondPurpose: {
      type: String,
      default: "listing",
    },
    isAbjad: Boolean,
  },
  components: {
    ModalMedicineReport,
    XLSX,
  },

  data() {
    return {
      model: null,
      formActive: {
        id: "",
      },
      resultData: "",
      loadTable: false,

      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Filter
      filterShow: false,
      filter: {
        name: "",
        medicine_category_id: "",
        medicine_packages_id: "",
        EcommerceFilter: "default",
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
      },
      noPaginations: [],
      // other
      packageReset: false,
      // Table Head
      fieldPopup: [
        {
          key: "number",
          label: "#",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "generic_name",
          label: "Nama Umum",
          sortable: true,
        },
        {
          key: "medicine_category_name",
          label: "Kategori",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "medicine_remaining_stock",
          label: "Sisa Stok",
          sortable: true,
        },
        {
          key: "uom_name",
          label: "Satuan",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aksi",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
      ],
      // Table Items
      items: [],
      itemProps: [],
      medicine_categories: [],
      medicine_packages: [],
      total_medicine: "",
      default_total_medicine: "",
      medicineItem: [],
      excelData: [],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {

    // Filter
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    filterByName() {
      this.pagination();
    },

    resetFilter() {
      this.filter.name = ""
      this.filter.medicine_category_id = ""
      this.filter.medicine_packages_id = ""
      this.pagination();
    },

    async filterByMedicineCategory(evt) {
      if (!evt) {
        this.filter.medicine_category_id = await "";
      } else {
        this.filter.medicine_category_id = await evt;
      }
      this.pagination();
    },

    // Request
    async pagination() {
      let filterParams = `&name=${this.filter.name}&medicine_category_id=${this.filter.medicine_category_id}`
      // for table
      let response = await module.paginate(
        "medicines",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      )

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.total_medicine = response.meta.total_medicine;
      this.default_total_medicine = response.meta.total_medicine;
      this.items = response.data
      let c
      for (c = 0; c < this.items.length; c++) {
        const row = this.items[c]
        // add other key-value
        row.label = row.name
        row.price = row.sales_price
        row.const_price = row.sales_price
        row.discount_percentage = 0
        row.discount_amount = 0
        row.discount_type = 0
        row.quantity = 1
        // row.promo_amount = this.getPromoAmount(row)
        // add row color
        if (
          row.medicine_remaining_stock <
          row.minimum_stock &&
          row.medicine_remaining_stock != 0
        ) {
          row._rowVariant = "warning";
        } else if (row.medicine_remaining_stock == 0) {
          row._rowVariant = "danger";
        }
      }
    },

    async getMedicineCategoryOption() {
      let response = await module.setTreeSelect("medicine-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.medicine_categories = response.data;
        this.medicine_categories.unshift({
          label: "Saring Berdasar Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMedicinePackageOption() {
      let response = await module.setTreeSelect("medicine-packages");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoadPackage = true;
        this.medicine_packages = response.data;
        this.medicine_packages.unshift({
          label: "Shortcut Obat dan Alkes",
          id: "null",
          isDisabled: true,
        });
      }
    },

    // Method
    chooseMedicine(value) {
      console.info('emit-table', value)
      this.$emit("chooseMedicine", value);
    },
  },
  mounted() {
    this.$root.$on("updateMedicine", (data) => {
      this.updateMedicine(data)
    })

    this.pagination();
    this.getMedicineCategoryOption();
    this.getMedicinePackageOption();
  },

}
</script>


<style scoped>
.border-filter {
  border-color: #8950FC !important;
  font-size: 18px !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
}

.active-abjad-filter {
  background-color: #8950FC !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 40px !important;
  padding: 0 16px;
}
</style>
